import { PopularLocations } from "../../../shared"
import { prescriberStates } from "../../../constants"

export const PrescribersPopularStates = () => {
  return (
    <PopularLocations
      carePersonType="prescribers"
      locations={prescriberStates}
      title="Find a trustworthy Cerebral prescriber in your state"
    />
  )
}
