import { PopularLocations } from "../../../shared"
import { prescriberCities } from "../../../constants"

export const PrescribersPopularCities = () => {
  return (
    <PopularLocations
      carePersonType="prescribers"
      locations={prescriberCities}
      title="We're in your city"
    />
  )
}
