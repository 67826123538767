import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { CareTeamPrescribersPage } from "../components/CareTeamPages/CareTeamPrescribersPage"
import { CareTeamTherapistsAndPrescriberPageProps } from "../components/CareTeamPages/shared/types"

interface IPageProps {
  data: CareTeamTherapistsAndPrescriberPageProps
}
export default function DynamicPage({ data }: IPageProps): JSX.Element {
  const topics = data.popular_topics.edges
  return (
    <Layout>
      <CareTeamPrescribersPage topics={topics} />
    </Layout>
  )
}

export const query = graphql`
  query CareTeamTherapistsPageQuery {
    popular_topics: allPrismicFaqRedesign(
      filter: {
        data: { categories: { elemMatch: { category: { eq: "Medication" } } } }
      }
    ) {
      edges {
        node {
          data {
            question {
              text
            }
            categories {
              category
            }
          }
          uid
        }
      }
    }
  }
`
