import { FaqTopics } from "../../../../shared"
import { FaqType } from "../../../../FaqPages/shared/types"
import { PopularTopic } from "../../../shared/types"

export const MedicationFaqs = ({
  topics,
}: {
  topics: FaqType[] | PopularTopic[]
}): JSX.Element => {
  return (
    <FaqTopics
      modifiedHeader={true}
      title={"Learn more about our medication services"}
      topics={topics}
    />
  )
}
